import React from "react"
import Layout from "../components/layout"
import Image  from "../components/image"
import ContactForm from "../components/contact"
import {Container,Row,Col} from 'react-bootstrap'
import SEO from "../components/seo"


const HomeAutomation =()=>{
    return(
    <Layout>
        <SEO title="Home-Automation" />
        <div  className="homeAuto">
            <Image alt="home automation" filename="homeatomation.png"  />
            <div className="automationImage">
                <h1 style={{fontWeight:"bold"}} className="homehead">THIS IS THE ERA OF HOME AUTOMATION</h1>
                <p className="homeheadpara">Trace Width is leading the way forward with cutting edge Home Automation.</p>
            </div>
        </div>
        <Container>
            <Row className="aboutrow">
                <Col sm={12} lg={6}>
                <p className="automationPara">Trace Width’s home automation systems promise you a hassle-free, safe and secure lifestyle for your users. </p>

                <p className="automationPara">The future is now and Trace Width Technology delivers home automation integration and solutions that improve quality of living, from ease of use, comfort, safety, management of energy consumption and more.</p>
                </Col>
                <Col sm={12} lg={6}>
                    <Image alt="auto" filename="auto.png"  />
                </Col>
            </Row>
        </Container>

            <Container >
                <h3 className="aboutTitle mt-5">SIMPLY CONVENIENT, COMPLETELY AUTOMATED</h3>
                <Row>
                    <Col sm={12} lg={3} className="aboutCol">
                        <Image alt="discovery" filename="Discovery.png" className="imghome"  />
                        <div>
                            <h3 className="abouth31">Discovery</h3>
                            <p className="aboutP">It all starts with a conversation about how you feel technology and entertainment can help you optimally integrate into the home automation environment. We listen carefully to ensure that we establish a very clear picture of how to best design a custom system just for you.</p>
                        </div>
                    </Col>
                    <Col sm={12} lg={3} className="aboutCol" >
                        <Image alt="design" filename="Design.png" className="imghome" />
                        <div>
                            <h3 className="abouth31">Design</h3>
                            <p className="aboutP">At Trace Width, we do not take design lightly. We find every possible way to integrate our automation technology within the home. We strive for our systems to flow within the space and be almost invisible.</p>
                        </div>
                    </Col>
                    <Col sm={12} lg={3} className="aboutCol">
                        <Image alt="build" filename="Build.png" className="imghome"/>
                        <div>
                            <h3 className="abouth31">Build</h3>
                            <p className="aboutP">We engineer with technology-leading components and cables to ensure the highest quality and seamless integration.</p>
                        </div> 
                    </Col>
                    <Col sm={12} lg={3} className="aboutCol">
                        <Image alt="program" filename="Program.png" className="imghome"/>
                        <div>
                            <h3 className="abouth31">Program</h3>
                            <p className="aboutP">Well-designed and easy-to-use systems require varying disciplines with great skill. We will program your systems as if we were going to move in and use them personally. Reliable and intuitive systems is what Tracewidth delivers.</p>
                        </div> 
                    </Col>
                </Row>
            </Container>
            <div className="homeForm">
                <Container >    
                    <Row className="homeImageRow">
                        <Col sm={4}><Image alt="home" filename="home.png" style={{width: '280px'}}/></Col>
                        <Col sm={8} className="homeImageText">Trace Width Technology Solutions offers complete home automation systems. Your Hardware, software, apps, wiring plans, and cloud services all working together seamlessly.</Col>
                    </Row>
               </Container>
            </div>
            <Container>
                <Row>
                    <Col sm={12} lg={6} className="aboutCol">
                       <Image alt="test" filename="1_06.png" className="imgpic" />
                        
                        <div>
                            <h3 className="homeh3">Test</h3>
                            <p className="homeautopara">That’s part of our core – no system gets handed over to a client until we thoroughly test and re-test the functionality of the system and the user interface. Our core beliefs set us apart from the rest of the industry!</p>
                        </div> 
                    </Col>

                    <Col sm={12} lg={6} className="aboutCol">
                        <Image alt="collaboration" filename="4_12.png" className="imgpic" />
                        <div>
                            <h3 className="homeh3">Collaboration</h3>
                            <p className="homeautopara">To find success, your project includes many people working together collegially and collaboratively, the Smart Touch way! We work closely with you and everyone involved to develop your whole home command solution to ensure that your project is efficiently and effectively delivered on-time, on-budget, and on-plan.</p>
                        </div> 
                    </Col>
                </Row>

                <Row>
                    <Col sm={12} lg={6} className="aboutCol">
                        <Image alt="entrance" filename="3_13.png" className="imgpic" />
                        <div>
                            <h3 className="homeh3">Entrance Management</h3>
                            <p className="homeautopara">Know exactly what’s happening at your doorstep wherever you are. With our integrated setup of cameras, mics, and speakers, you have complete control of every aspect of the home environment.</p>
                        </div> 
                    </Col>

                    <Col sm={12} lg={6} className="aboutCol">
                        <Image alt="collaboration" filename="2_08.png" className="imgpic"  />
                        <div>
                            <h3 className="homeh3">Safety and Security management</h3>
                            <p className="homeautopara">Keep your home safe and secure even when you’re away. You can always keep an eye on the security and status of your home when using smart automated technology, which is installed at all the key points by our world-class experts.</p>
                        </div> 
                    </Col>
                </Row>
            </Container>
            <div className="headerForm"> 
               
                 <br/>
                <div className="homeCardrow5">Step into fast growing market of Home Automation with Trace Width as your trusted source for success today!</div>
                <ContactForm  />
               
            </div>
          
    </Layout>
);

}

export default HomeAutomation;

